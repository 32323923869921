<template>
  <div class="annoWrap">
      <div class="title" v-if='!toDetail'>
          <span>Announcements</span>
        <div class="center" @click.stop='toggleView'>
          <span class="top">One World, One ID — MetaCoin Whitepaper Official Release</span>
          <span class="foot">2021-07-29 <i class="iconfont icon-xiangyoujiantou"></i></span>
      </div>
    </div>
    <div v-else class="detail">
        <div class="return" @click.stop="toggleView">
            <img src="../assets/img/icon_return@2x.png" alt="">
            <span>Back</span>
        </div>
        <div class="band">
            <span class="top">One World, One ID — MetaCoin Whitepaper Official Release</span>
            <span class="time">2021-07-29</span>
        </div>
        <div class="content">
            <span>We are excited to announce that MetaCoin Whitepaper is officially released today! </span>
            <span> MetaCoin is a token released by OpenMetaNetFoundation to motivate users to use MetalD applications and create MetalD transactions.</span>
            <span>This token does not need to be purchased by users. After users use MetalD applications and create quantitative MetalD transactions, they can get the corresponding MetaCoin presented by the issuer. MetaCoin uses the PoTS allocation mechanism, which represents the contribution of users using MetalD applications.</span>
            <span>In the past testing phase, we used the Proof of Mining Fee(PoMF) as the consensus mechanism for MetaCoin issuance. Starting from the 128th block (UTC August 2nd, 2021), we decided to switch from using Proof of Mining Fee(PoMF) to using the Proof of Transaction Size (PoTS) mechanism to ensure the issuance of MetaCoin is more reasonable and fair in measuring the user's contribution in MetalD ecosystem.</span>
            <span>Click to read more about the concept, publisher, and distribution method of MetaCoin:</span>
        </div>
      <Download class="footer"></Download>
    </div>
       
       <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../components/footer.vue'
import Download from '../components/download.vue'
export default {
    name:"Announcements",
     components:{
        Footer, Download
    },
    data(){
        return{
            toDetail:false
        }
    },
    methods:{
        toggleView(){
            this.toDetail=!this.toDetail
        }
    }

}
</script>

<style lang='scss' scoped>
    .annoWrap{
    width: 80vw;
    padding-left: 8rem /* 112/14 */;
    padding-right: 8rem /* 112/14 */;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title{
        display: flex;
        flex-direction: column;
         padding-top: 11.571429rem /* 162/14 */;
        span{
            font-size: 2.571429rem /* 36/14 */;
        color: #101315;
        font-weight: 500;
       
        }
        .center{
        display: flex;
        flex-direction: column;
        margin-top: 3.571429rem /* 50/14 */;
        width: 100%;
        background: rgba(49, 130, 249, 0.05);
        line-height: 2.785714rem /* 39/14 */;
        padding: 1.428571rem /* 20/14 */;
        cursor: pointer;
        .top{
           font-size: 2rem /* 28/14 */;
        }
        .foot{
            display: flex;
            align-items: center;
            font-size: 1.285714rem /* 18/14 */;
            color: #ABB3B8;
            i{
                font-size:2.857143rem /* 40/14 */;
            }
           
        }
    }
    }
    .detail{
        margin: 0 auto;
        padding-top: 11.571429rem /* 162/14 */;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;

        width: 70%;
        .return{
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              cursor: pointer;
              img{
                  width: 1rem /* 14/14 */;
                  height:1rem /* 14/14 */;
              }
              span{
                  margin-left: .714286rem /* 10/14 */;
                  font-size: 1.285714rem /* 18/14 */;
              }
        }
        .band{
            display: flex;
            flex-direction: column;
            margin-top: 2.142857rem /* 30/14 */;
            line-height: 2.714286rem /* 38/14 */;
            .top{
                font-size: 2.5rem /* 35/14 */;
                color: #101315;
            }
            .time{
                font-size: 1.285714rem /* 18/14 */;
                color: #ABB3B8;
            }
        }
        .content{
            // height: 60%;
            line-height: 2.142857rem /* 30/14 */;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            
            span{
                 font-size: 1.285714rem /* 18/14 */;
                margin-top: 1.785714rem /* 25/14 */;
            }
        }
        .footer{
            margin-top:3.571429rem /* 50/14 */ !important;
        }
       
       
    }
  
}
@media screen and (max-width:768px){
   .annoWrap{
    width: 80vw;
    padding-left: 2.142857rem /* 30/14 */;
    padding-right: 8rem /* 112/14 */;
}
}
@media screen and (max-width:500px){
    .annoWrap{
       
           width: 100vw;
        padding-left:.714286rem /* 10/14 */;
        padding-right: .714286rem /* 10/14 */;
       
        .title{
            
            padding-top: 12.142857rem /* 170/14 */;
            .center{
                width: 95vw;
            }
        }
        .detail{
            width: 95vw;
            
           padding-top: 10.714286rem /* 150/14 */;
           .content{
               padding: 0 .714286rem /* 10/14 */;
           }
        }
    }
}
</style>